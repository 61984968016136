import * as HttpService from './http.service';
import * as URL from './url.service';

export const loginUser = (data, token) => HttpService.postWithAuth(URL.USER_LOGIN_URL, data, token);

export const createUser = (data) => HttpService.postWithAuth(URL.CREATE_USER_URL, data);

export const getPaginatedUsers = (offset, limit, role, token, phrase) => HttpService
    .getWithAuth(URL.GET_PAGINATED_USER_URL(offset, limit, role, phrase), token);
export const getPaginatedUsersForCompliance = (offset, limit, token, phrase) => HttpService
    .getWithAuth(URL.GET_PAGINATED_USER_FOR_COMPLIANCE_URL(offset, limit, phrase), token);
export const getChatTokenForCompliance = ({ userId, token }) => HttpService
    .getWithAuth(URL.GET_CHAT_TOKEN_FOR_COMPLIANCE_URL(userId), token);
export const getHootsForCompliance = (data, token) => HttpService
    .postWithAuth(URL.GET_HOOTS_FOR_COMPLIANCE_URL(), data, token);
export const getHootSearchUserForCompliance = (data) => HttpService
    .getWithAuth(URL.GET_HOOT_SEARCH_USER_FOR_COMPLIANCE_URL(data));

export const getHootSignedUrl = ({ id, token }) => HttpService
    .getWithAuth(URL.GET_HOOT_SIGNED_BY_ID_URL({ id }), token);

export const updateUser = (id, data) => HttpService.putWithAuth(URL.UPDATE_USER_URL(id), data);

export const userDetails = () => HttpService.getWithAuth(URL.GET_USER_DETAILS_URL());

export const sendForgotPasswordEmail = (email) => HttpService.postWithOutAuth(URL.SEND_FORGOT_PASSWORD_EMAIL_URL, { email });

export const editUserProfile = (id, data) => HttpService.putWithAuth(URL.EDIT_USER_PROFILE_URL(id), data);

export const updateUserStatus = (id, data) => HttpService.patchWithAuth(URL.UPDATE_USER_STATUS_URL(id), data);

export const sessionLogout = () => HttpService.postWithAuth(URL.SESSION_LOGOUT);
