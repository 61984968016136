import { signOut } from 'firebase/auth';
import {
    doc
} from 'firebase/firestore';
import {
    complianceRoles, popupSpecs, popupSpecsForDes, popupSpecsForTrade
} from 'lib/constants';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import GlobalSearch from 'organisms/GlobalSearch';
import { useEffect, useState } from 'react';
import { Bell, HardDrive, MessageSquare } from 'react-feather';
import { getAuth, logout } from 'services/auth.service';
import { getMediaFromCloudFrontUrl } from 'services/aws.service';
import { db, auth as firebaseAuth, updateDocs } from 'services/firebase.service';
import { socket, socketEmit } from 'services/socket';
import MenuList from '../atoms/MenuList';
import Text from '../atoms/Text';
import ActionList from '../molecules/ActionList';

const menus = {
    superMenu: [
        {
            id: 1,
            title: 'Dashboard',
            isActive: false,
            link: '/dashboard'
        },
        {
            id: 2,
            title: 'Users',
            isActive: false,
            link: '/users'
        },
        {
            id: 3,
            title: 'Sectors',
            isActive: false,
            link: '/sectors'
        },
        {
            id: 4,
            title: 'Companies',
            isActive: false,
            link: '/companies'
        },
        {
            id: 5,
            title: 'Bonds',
            isActive: false,
            link: '/bonds'
        }
    ],
    userMenu: [
        {
            id: 1,
            title: 'Dashboard',
            isActive: false,
            link: '/dashboard'
        },
        {
            id: 2,
            title: 'My Orders',
            isActive: false,
            link: '/my-orders'
        },
        {
            id: 3,
            title: 'Recent',
            isActive: false,
            link: '/recent'
        },
        {
            id: 4,
            title: 'Sectors',
            isActive: false,
            link: '/sectors/interest'
        },
        {
            id: 5,
            title: 'Matching',
            isActive: false,
            link: '/matchings'
        }
    ]
};

const Header = ({ }) => {
    const [menuItem, setMenu] = useState([]);
    const [user, setUser] = useState({});
    const router = useRouter();

    const logoutFn = async () => {
        socketEmit('/post/users/sessions/logout');
        if (socket.connected) socket.disconnect();
        const auth = getAuth();
        try {
            const userDocRef = doc(db, 'presence', auth?.id);
            await updateDocs(userDocRef, {
                online: false
            });
            await signOut(firebaseAuth);
        } catch (e) { console.log(e); }
        logout();
    };

    const onSessionCreated = (data) => {
        if (data.isDeactivatedUser) logoutFn();
    };

    useEffect(() => {
        socket.on('/post/users/sessions/:sessionId', onSessionCreated);
        socket.on('/user-status', onSessionCreated);
        if (router.pathname) {
            const data = getAuth();
            setUser(data);
            setMenu(
                (data?.role === 'SuperAdmin' ? menus.superMenu : menus.userMenu).map((item) => {
                    if (router.pathname === item.link) return { ...item, isActive: true };
                    return { ...item, isActive: false };
                })
            );
        }
        return () => {
            socket.off('/post/users/sessions/:sessionId', onSessionCreated);
            socket.off('/user-status', onSessionCreated);
        };
    }, [router.pathname]);

    if (!user?.token) return null;
    const HeaderPaths = ['/my-orders', '/dashboard', '/recent', '/sectors/interest', '/matchings', '/bonds', '/users', '/sectors', '/companies',
        '/compliance/users'
    ];
    if (!HeaderPaths.includes(router.pathname)) return null;

    const openChatWindow = () => {
        const url = '/chat';
        const windowName = 'chat';
        window.open(url, windowName, popupSpecs);
    };

    return (
        <div className='sticky top-0 z-50 bg-basic-200'>
            <div className='px-6 py-3 flex justify-between items-center h-16'>
                <div className='2xl:w-2/12'>
                    <div className='h-[31px] w-[50px] relative'>
                        <Image src="/images/LOGO.svg" alt='logo' layout='fill' />
                    </div>
                </div>

                {!complianceRoles.includes(getAuth()?.role) && <div className='w-8/12 flex items-center justify-center'>
                    <div className="mr-1 w-80 xl:min-w-96 2xl:min-w-128">
                        <GlobalSearch inputClass="h-9" globalSearchList="w-80 lg:w-128 xl:w-128"
                            voiceCommand={['/dashboard'].includes(router.pathname)} />
                    </div>
                    <MenuList menuItem={menuItem} className="text-gray-100 break-keep" listClass="xl:flex hidden" />
                </div>}

                <div className='w-3/12'>
                    <div className='flex items-center justify-end gap-2 lg:gap-6'>
                        {!complianceRoles.includes(getAuth()?.role) && <div className='flex items-center gap-5'>
                            <Image src="/images/icons/speaker.svg"
                                width={18}
                                height={18}
                                alt='Hoot'
                                className='cursor-pointer'
                                onClick={() => {
                                    const windowFeatures = 'popup=true,left=0,top=0,right=0,bottom=0,width=528,height=2000';
                                    window.open(`${process.env.NEXT_PUBLIC_UI_URL}hoot`, 'hoot', windowFeatures);
                                }} />

                            <MessageSquare size={18} className='text-gray-100 cursor-pointer' onClick={() => openChatWindow()} />

                            <HardDrive
                                size={18}
                                className='text-gray-100 cursor-pointer'
                                onClick={() => window.open('/trades', 'trade', popupSpecsForTrade)} />

                            <Bell size={18} className='text-gray-100 cursor-pointer' />
                        </div>}

                        <div className='flex items-center gap-2 text-right'>

                            <div className='w-12 lg:w-24'>
                                <Text className='text-gray-100 truncate'
                                    fontWeight='font-semibold' variant='bodySmall' title={`${user?.name || ''}`}>{user?.name || ''}</Text>
                                <Text fontWeight='font-semibold' variant='caption'
                                    className=' text-gray-200 truncate' title={`${user?.companyName || ''}`}>{user?.companyName || ''}</Text>
                            </div>

                            <ActionList options={[{
                                id: '1',
                                onClick: () => {
                                    window.open('/profile', nanoid(5), popupSpecsForDes);
                                },
                                icon: '',
                                label: 'My Profile'
                            }, {
                                id: '2', onClick: () => { router.push('/change-password'); }, icon: '', label: 'Change Password'
                            }, (getAuth()?.role === 'SuperAdmin' ? {
                                id: '3',
                                onClick: () => { window.open('/holidays', 'holiday', popupSpecsForDes); },
                                icon: '',
                                label: 'Holiday Calendar'
                            } : {}), {
                                id: '4', onClick: logoutFn, icon: '', label: 'Logout'
                            }].filter((item) => item.id)} >

                                {user?.profileUrl ? <Image
                                    src={getMediaFromCloudFrontUrl(user.profileUrl)}
                                    width={40}
                                    height={40}
                                    className="w-full h-full object-cover rounded-full select-none"
                                    alt={user.profileUrl} />
                                    : <div
                                        className='text-basic-100 bg-primary-300 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer'>{user?.name?.slice(0, 1).toUpperCase()}</div>}
                            </ActionList>
                        </div>
                    </div>
                </div>
            </div>
            {!complianceRoles.includes(getAuth()?.role)
            && <MenuList menuItem={menuItem} className="text-gray-100  break-keep" listClass="xl:hidden justify-between max-w-[500px] mt-2 mx-auto " />
            }
        </div>
    );
};

export default Header;
